.home-container {
    width: 100%;
    height: 100vh; /* 使背景填满整个视口高度 */
    background: linear-gradient(135deg, #001529cc 0%, #4b6cb7cc 100%), url('../../assets/images/background.png'); /* 渐变背景和图片结合 */
    background-size: cover; /* 使背景图片覆盖整个容器 */
    background-position: center; /* 背景图片居中 */
    background-blend-mode: overlay; /* 确保背景图片和渐变背景都能显示 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    animation: fadeIn 2s ease-in-out; /* 添加淡入动画 */
}

.home-title {
    color: #fff !important;
    text-shadow: #1e0737 2px 1px 0;
    font-size: 3em; /* 增加字体大小 */
    margin-bottom: 20px;
    opacity: 0;
    animation: slideIn 1.5s ease-in-out forwards; /* 添加滑入动画 */
}

.home-desc {
    color: #EEE;
    text-shadow: #0e2439 2px 1px 0;
    font-size: 1.5em; /* 增加字体大小 */
    margin-bottom: 20px;
    opacity: 0;
    animation: slideIn 1.5s ease-in-out forwards 0.5s; /* 添加滑入动画，延迟0.5秒 */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.home-button {
    background-color: #1890ff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s, transform 0.5s;

    opacity: 0;
    animation: slideIn 1.5s ease-in-out forwards 0.8s; /* 添加滑入动画，延迟0.5秒 */
}

.home-button:hover {
    background-color: #40a9ff;
    transform: scale(1.1);
}