@import '../Common/variables.less';

.append-entities-modal {
    .ant-modal {
        width: 960px;
    }

    .ant-form-item {
        margin-bottom: 16px;
    }

    .ant-input {
        width: 100%;
    }

    .ant-btn-dashed {
        margin-top: 8px;
    }

    .ant-list {
        .ant-list-item {
            .ant-card {
                width: 100%;
                .ant-card-head {
                    .ant-card-head-title {
                        font-size: @font-size-md;
                    }
                }
                .ant-card-extra {
                    .ant-btn-link {
                        color: @color-error;
                    }
                }
            }
        }
    }

    .pagination-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .search-input {
        width: 120px;
    }
}