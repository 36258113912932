@mobile-breakpoint: 768px;

.profile-layout {
    min-height: 100vh;
}

.profile-content {
    padding: 24px;
}

.profile-section {
    margin-bottom: 24px;
}

@media (max-width: @mobile-breakpoint) {
    .profile-content {
        padding: 16px;
    }

    .profile-section {
        margin-bottom: 16px;
    }
}