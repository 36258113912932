/* 定义变量 */
@container-bg-color: #f0f2f5;
@card-max-width: 400px;
@card-padding: 20px;
@box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
@margin-bottom: 24px;

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: @container-bg-color;
}

.login-card {
    width: 100%;
    max-width: @card-max-width;
    padding: @card-padding;
    box-shadow: @box-shadow;
}

.login-title {
    text-align: center;
    margin-bottom: @margin-bottom;
}

.login-button {
    width: 100%;
}