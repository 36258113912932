@import '../Common/variables.less';

.monster-card {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    position: relative;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border: 1px solid rgba(255, 215, 0, 0.3);

    &:hover {
        box-shadow: 0 10px 20px rgba(255, 215, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.1);
        transform: translateY(-3px);
    }

    .monster-image-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .monster-info-bar {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding: 5px;
    }

    .monster-title-container {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        z-index: 2;
        transition: transform 0.5s ease, opacity 0.5s ease;

        &.hide {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    .monster-title {
        color: #fff;
        * {
            color: #fff;
        }
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
        margin: 0;
        padding: 8px 12px;
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%);
        border-left: 3px solid #ff4d4d;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        word-wrap: break-word;
        max-height: 80px;
        overflow-y: auto;
    }

    .monster-front-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px 18px 18px;
        overflow-y: auto;
        font-size: 16px;
        line-height: 1.6;
        color: rgba(0, 0, 0, 0.85);
        background-color: rgba(255, 255, 255, 0.98);
        transition: transform 0.5s ease, opacity 0.5s ease;
        transform: translateY(100%);
        opacity: 0;

        &.show-full-content {
            transform: translateY(50px);
            opacity: 1;
        }
    }

    .monster-result-info {
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        padding: 12px;
        border-radius: 8px;
        margin: 10px 0 15px;
        font-size: 14px;
        line-height: 1.4;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        p {
            margin: 6px 0;
        }
    }
}

.difficulty-importance {
    margin-right: 10px;
    flex-shrink: 0;
}

// 移除 .monster-health-bar 相关样式
