@import '../Common/variables.less';




.book-detail-container {
    background-color: @page-background-color;
    padding: 16px;
    color: @page-text-color;
    transition: all 0.3s ease;

    h2 {
        font-size: 28px;
        color: @page-header-primary-color;
        margin-bottom: 20px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

        @media (max-width: 768px) {
            margin-bottom: 2px;
        }
    }

    @media (max-width: 768px) {
        padding: 6px;
    }
}

.book-detail-content {
    background: #ffffff;
    padding: 24px;
    border-radius: @page-container-border-radius;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    }

    .ant-form-item {
        margin-bottom: 24px;
    }

    .ant-input, .ant-input-textarea {
        border-color: #d9d9d9;
        transition: all 0.3s ease;

        &:hover, &:focus {
            border-color: @page-header-primary-color;
            box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
        }
    }
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h1 {
        margin: 0;
    }

    .id-container {
        display: flex;
        align-items: center;
    }
}

.bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 6px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.tag-container {
    flex: 1;
    margin-right: 20px;

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2px;
    }
}

.action-buttons-container {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.book-items-container {
    margin-top: 40px;
    padding: 12px;
    background: #ffffff;
    border-radius: @page-container-border-radius;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

    h1, h2 {
        font-size: 24px;
        color: @page-header-primary-color;
        margin: 0 12px 12px 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 0.3s ease-out;
}