.monster-health-bar-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.monster-health-bar-prefix {
    margin-right: 10px; // 添加右侧间距
    flex-shrink: 0; // 防止被压缩
}

.monster-health-bar {
    flex-grow: 1;
    flex-shrink: 1; // 允许被压缩
    min-width: 100px; // 设置最小宽度，防止过度压缩
    position: relative;
    height: 14px;
    background-color: rgba(44, 44, 44, 0.6);
    border-radius: 2px;
    overflow: hidden;
}

.health-bar-fill {
    height: 100%;
    background: linear-gradient(to right, #721f1f, #cd1f1f, #ff4d4d, #ffa64d,  #52c41a);
    transition: width 0.5s ease-out;
    position: absolute;
    top: 0;
    left: 0;
}
 
.health-bar-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    pointer-events: none;
}
